<template>
  <admin>
    <metatag title="Survey Summary"></metatag>
    <page-header>
      <template #title> DCSI Survey Summary </template>
      <template #action>
        <v-btn
          :block="$vuetify.breakpoint.smAndDown"
          large
          color="grey"
          class="white--text"
          exact
          @click="download"
          :loading="downloadLoading"
          :disabled="downloadLoading"
        >
          <v-icon small left>mdi-cloud-download</v-icon>
          <span v-text="'Download'"></span>
        </v-btn>
      </template>
    </page-header>

    <v-card>
      <can :code="['admin', 'admin_survey']">
        <survey-filter
          ref="toolbar"
          :advanceFilter="true"
          :filterSearch="false"
          :filterDateRange="true"
          :filterRegions="true"
          :filterGroups="true"
          :filterZones="true"
          :filterDealer="true"
          :filterFactors="true"
          :isSearchColsAuto="true"
          :bottomdivider="false"
          :items.sync="tabletoolbar"
          :factorCategory="'service'"
          @update:search="setSearch"
          @update:daterangesurvey="setDateRange"
          @update:regions="setRegions"
          @update:zones="setZones"
          @update:groups="setGroups"
          @update:dealer="setDealer"
          @update:factors="setFactors"
          @update:loadFilters="loadFilters"
        />
      </can>

      <can :code="['dealer_survey', 'dealer']">
        <survey-filter
          ref="toolbar"
          :showAdvanceFilterBtn="false"
          :advanceFilter="false"
          :filterSearch="false"
          :filterDateRange="true"
          :filterRegions="false"
          :filterGroups="false"
          :filterZones="false"
          :filterDealer="false"
          :filterFactors="true"
          :isSearchColsAuto="true"
          :bottomdivider="false"
          :items.sync="tabletoolbar"
          :factorCategory="'service'"
          @update:search="setSearch"
          @update:daterangesurvey="setDateRange"
          @update:regions="setRegions"
          @update:zones="setZones"
          @update:groups="setGroups"
          @update:dealer="setDealer"
          @update:factors="setFactors"
          @update:loadFilters="loadFilters"
        />
      </can>
    </v-card>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title
            style="border-radius: 0px"
            class="pa-1 pl-2 bg-steel-blue white--text text-uppercase"
            >{{
              selectedFactor.title ? selectedFactor.title : "Factor Score"
            }}</v-card-title
          >
          <v-divider></v-divider>
          <v-card-text>
            <div>
              <!-- <column-chart
								:data="factorScore.data"
								:library="factorScore.options"
								:colors="['#4CAEE3', '#D8392B']"
							></column-chart> -->
              <canvas id="factor-score-chart"></canvas>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="false">
      <template v-for="(question, key) in questions">
        <v-col :key="key" cols="4">
          <v-card height="425px">
            <v-card-title
              class="pa-1 pl-2 bg-steel-blue white--text font-weight-regular"
            >
              <div style="line-height: 1">
                <v-chip class="d-inline font-weight-bold">
                  {{ `Q${key + 1}` }}
                </v-chip>
                <span class="d-inline">
                  <small> {{ question.title }} </small>
                </span>
              </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="d-flex align-center">
              <bar-chart
                :data="question.questionDetails"
                :colors="chartColors"
              ></bar-chart>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </admin>
</template>

<script>
import Vue from "vue";
import Chartkick from "vue-chartkick";
import Chart from "chart.js";
import { mapActions, mapGetters } from "vuex";
import apiSurveysService from "@/services/api/modules/surveysService";
import apiNewsService from "@/services/api/modules/surveyCdcService";
import { debounce } from "lodash";
import { COLORS } from "../config/chartConfig";

import ChartDataLabels from "chartjs-plugin-datalabels";

Vue.use(Chartkick.use(Chart));

export default {
  data: () => ({
    tabletoolbar: {
      isSearching: false,
      search: null,
      toggleBulkEdit: false,
      bulkCount: 0,
      actionDropdownItems: [
        {
          slug: "activate",
          action: "Activate",
        },
        {
          slug: "deactivate",
          action: "Deactivate",
        },
        {
          slug: "delete",
          action: "Delete",
        },
      ],
    },
    downloadLoading: false,
    items: [
      {
        text: "CS Dashboard",
        disabled: false,
        href: "cs-dashboard",
        routeName: "survey-reports.cs-dashboard",
      },
      {
        text: "Survey Ranking",
        disabled: false,
        href: "survey-ranking",
        routeName: "survey-reports.survey-ranking",
      },
      {
        text: "Survey Summary",
        disabled: false,
        href: "survey-summary",
        routeName: "survey-reports.survey-summary",
      },
      {
        text: "CDC Summary",
        disabled: false,
        href: "cdc-summary",
        routeName: "survey-reports.cdc-summary",
      },
      {
        text: "Dealer Action Plan",
        disabled: false,
        href: "dealer-action-plan",
        routeName: "survey-reports.dealer-action-plan",
      },
    ],
    surveys: [],
    survey: {},
    questions: [],
    factors: [],
    factor: {},
    chartColors: COLORS,
    factorScore: {
      data: null,
    },
    selectedFactor: "",
    selectedDateRange: "",
    selectedRegion: "",
    selectedZone: "",
    selectedgroup: "",
    selectedDealer: "",
    chartJS: "",
  }),
  watch: {
    survey(newValue) {
      let questions = [];
      newValue.questions_data.pages.forEach((page) => {
        page.elements.forEach((element) => {
          questions.push({
            title: element.title,
          });
        });
      });

      questions.forEach((question) => {
        let questionDetails = {};
        const min = 3;
        const max = 10;
        const tempQuestionLength = Math.floor(
          Math.random() * (max - min) + min
        );

        for (let index = 0; index < tempQuestionLength; index++) {
          questionDetails[`answer_${index + 1}`] = Math.floor(
            Math.random() * 100
          );
        }

        question.questionDetails = questionDetails;
      });

      this.questions = questions;
    },
  },
  computed: {
    ...mapGetters({
      summaries: "surveyResults/GET_SURVEY_DCSI_SUMMARY",
    }),
  },
  methods: {
    ...mapActions({
      getDCSIGraphData: "surveyResults/getDCSIGraphData",
      downloadDCSISummary: "surveyResults/downloadDCSISummary",
      getDealerZones: "dealerZone/getDealerZones",
      getDealers: "dealer/getDealers",
    }),
    setSearch: debounce(async function (e) {
      console.log(e);
    }, 300),

    setDateRange(e) {
      this.selectedDateRange = e;

      this.getSummaries();
    },

    async setRegions(e) {
      this.selectedRegion = e;

      this.getSummaries();

      await this.getDealerZones([this.selectedRegion]);

      await this.getDealers({
        region_types: this.selectedRegion ? [this.selectedRegion]: [],
        zones: this.selectedZone ? [this.selectedZone] : [],
        groups: this.selectedgroup ? [this.selectedgroup] : [],
      });
    },

    setZones(e) {
      this.selectedZone = e.value;

      this.getSummaries();
    },

    setGroups(e) {
      this.selectedgroup = e.value;

      this.getSummaries();
    },

    setDealer(e) {
      this.selectedDealer = e;

      this.getSummaries();
    },

    setFactors(e) {
      this.selectedFactor = e;

      this.getSummaries();
    },
    loadFilters(e) {
      this.selectedFactor = e.factor;
      this.selectedDateRange = e.dateRange;

      this.getSummaries();
    },
    async getSurveyQuestions() {
      await apiSurveysService
        .list()
        .then((response) => {
          this.surveys = response.data.data;

          /**
           * For testing purpose only
           * Find data that have the highest number of questions
           */
          this.survey = response.data.data.reduce(
            (accumulator, currentValue) => {
              if (accumulator.question_count >= currentValue.question_count) {
                return accumulator;
              }

              accumulator = currentValue;

              return accumulator;
            }
          );
        })
        .catch(() => {
          this.surveys = [];
        });
    },
    async download() {
      this.downloadLoading = true;

      await this.downloadDCSISummary({
        filters: {
          dealer: this.selectedDealer,
          date: this.selectedDateRange,
          region: this.selectedRegion,
          zone: this.selectedZone,
          group: this.selectedgroup,
        },
      }).then(() => {
        this.downloadLoading = false;
      });
    },
    async getFactors() {
      await apiNewsService
        .getFactors()
        .then((response) => {
          console.log({ response });
          this.factors = response.data.data;
        })
        .catch(() => {
          this.factors = [];
        });
    },
    setupFactorScoreChart() {
      if (this.chartJS) {
        this.chartJS.destroy();
      }

      const ctx = document.getElementById("factor-score-chart");
      this.chartJS = new Chart(ctx, this.factorScore);
    },
    async getSummaries() {
      this.factorScore = {
        data: null,
      };

      await this.getDCSIGraphData({
        slug: this.selectedFactor.slug,
        filters: {
          dealer: this.selectedDealer,
          date: this.selectedDateRange,
          region: this.selectedRegion,
          zone: this.selectedZone,
          group: this.selectedgroup,
        },
      });

      const factorScores = [];

      const actualTargetData = {
        targetData: {
          label: "Target",
          data: [],
          type: "line",
          backgroundColor: "red",
          borderColor: "#D8392B",
          fill: false,
          order: 1,
          pointStyle: "rectRot",
          radius: 5,
          lineTension: 0,
        },
        actualData: {
          label: "Actual",
          data: [],
          backgroundColor: "#4CAEE3",
          order: 2,
        },
      };

      if (!this.isEmptyObj(this.summaries)) {
        if (this.summaries.questions.length > 0) {
          this.summaries.questions.forEach((question) => {
            let question_title = question.question_title;

            if (question_title.length >= 50) {
              question_title = question.question_title.substring(0, 50) + "...";
            }

            factorScores.push(question_title);
            actualTargetData.targetData.data.push(this.summaries.target_score);
            actualTargetData.actualData.data.push(question.score);
          });
        }
      }

      this.factorScore = {
        type: "bar",
        data: {
          datasets: [actualTargetData.targetData, actualTargetData.actualData],
          labels: factorScores,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          aspectRatio: 4,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
              }
            }]
          },
          plugins: {
            datalabels: {
              color: "#ffffff",
              anchor: "center",
              align: "end",
              formatter: function (value, context) {
                if (context.dataset.label == "Actual") {
                  return Math.round(value * 100) / 100;
                } else {
                  return null;
                }
              },
            },
          },
        },
        plugins: [ChartDataLabels]
      };

      this.setupFactorScoreChart();
    },
    isEmptyObj(obj) {
      if (obj) {
        return Object.keys(obj).length === 0;
      }

      return true;
    },
  },
  mounted() {
    // this.getSurveyQuestions();
    // this.getFactors();
    this.getSummaries();
  },
};
</script>
